// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store.getState().blockchain
      let paused = await store.getState().blockchain.smartContract.methods.paused().call();
      let publicSaleMintedAmount = await store.getState().blockchain.smartContract.methods.getUserMintedAmount(blockchain.account).call()

      dispatch(
        fetchDataSuccess({
          paused,
          publicSaleMintedAmount,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
